import React, { useState, useEffect } from "react";
import {
  Alert,
  Avatar,
  Button,
  Container,
  Icon,
  Panel,
  Loader,
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  Whisper,
  RangeSlider,
  Progress,
  ButtonGroup,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroup,
} from "rsuite";
import moment from "moment";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";

import L from "leaflet";
import tools from "../../lib/tools";
import EditableInput from "../../components/editableinput";
import ModeSelect from "./modeselect";
import Geofences from "./geofences";

import Minimap from "./minimap";
import LotPicker from "./lotpicker";

// GQL
import { useQuery, useApolloClient, useMutation } from "@apollo/react-hooks";
import GQL from "./graphql";

import { useApp } from "../../AppStore";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const isMobile = window.innerWidth < 992;
const maxReportDays = 180;
const EventTypeIcon = (props) => {
  return (
    <Whisper placement="top" speaker={<Tooltip>{props.label}</Tooltip>}>
      <Avatar
        circle
        size="xs"
        style={{
          backgroundColor: props.active === true ? "#2980B9" : "#D0D0D0",
        }}
        className="button-shadow"
      >
        <Icon
          icon={props.icon}
          style={{
            color: "white",
          }}
        />
      </Avatar>
    </Whisper>
  );
};

export default (props) => {
  const { setSelectedDevice } = props;
  const selectedDevice = {
    ...props.selectedDevice,
    last_report: JSON.parse(props.selectedDevice.last_report),
  };
  const [{ user }, actions] = useApp();
  //const [rangeDisabled, setRangeDisabled] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showEditDevice, setShowEditDevice] = useState(false);
  const [showLotPicker, setShowLotPicker] = useState(false);
  const [editName, setEditName] = useState(props.selectedDevice.name);
  const [editAttachedTo, setEditAttachedTo] = useState(
    props.selectedDevice.attached_to
  );
  const [email, setEmail] = useState("");
  //const [mapRef, setMapRef] = useState(null);
  const [start, setStart] = useState(maxReportDays - 30);
  const [end, setEnd] = useState(maxReportDays + 1);
  const [showGeofencesModal, setShowGeofencesModal] = useState(false);
  const [showModeChangeModal, setShowModeChangeModal] = useState(false);
  const [showRepoModal, setShowRepoModal] = useState(false);

  const apolloClient = useApolloClient();

  const { data, loading, refetch } = useQuery(GQL.Queries.device, {
    variables: {
      id: selectedDevice.id,
    },
    onCompleted: (res) => {
      //console.log({ res });
      if (
        res &&
        res.devices &&
        res.devices.length > 0 &&
        res.devices[0].last_report
      ) {
        //console.log(JSON.parse(res.devices[0].last_report));
        let selectedReport = JSON.parse(res.devices[0].last_report);
        //console.log({ selectedReport });

        selectedReport.send_time = moment
          .utc(selectedReport.send_time)
          .valueOf();
        if (selectedReport.gps_time) {
          selectedReport.gps_time = moment
            .utc(selectedReport.gps_time)
            .valueOf();
        }
        //console.log({ selectedReport });
        setSelectedReport(selectedReport);
      }
    },
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });
  const {
    data: reportData,
    loading: loadingReportData,
    refetch: refetchReportData,
  } = useQuery(GQL.Queries.device_reports, {
    variables: {
      device_id: selectedDevice.id,
      start: moment()
        .startOf("day")
        .subtract(maxReportDays - start, "days")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      end: moment()
        .startOf("day")
        .subtract(maxReportDays - end, "days")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
    },
    onCompleted: (res) => {
      //setRangeDisabled(false);
    },
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });
  const {
    data: unsent_commands,
    //loading: loading_unsent_commands,
    refetch: refetch_unsent_commands,
  } = useQuery(GQL.Queries.unsent_push_commands, {
    variables: {
      device_id: selectedDevice.id,
    },
  });
  const {
    data: repo_tokens,
    loading: loading_repo_tokens,
    refetch: refetch_repo_tokens,
  } = useQuery(GQL.Queries.repo_tokens, {
    variables: {
      device_id: selectedDevice.id,
    },
  });
  const [create_repo_token] = useMutation(GQL.Mutations.create_repo_token);
  const [update_repo_token] = useMutation(GQL.Mutations.update_repo_token);
  const [disable_repo_token] = useMutation(GQL.Mutations.disable_repo_token);
  const [enable_repo_token] = useMutation(GQL.Mutations.enable_repo_token);
  const [delete_repo_token] = useMutation(GQL.Mutations.delete_repo_token);

  const exportCsv = () => {
    //console.log(reportData.device_reports);

    const cleanedArray = reportData.device_reports.map(
      ({
        location,
        address,
        send_time,

        gps_time,
        event_type_id,
        location_accuracy,
      }) => {
        return {
          location: location.latitude + "," + location.longitude,
          address,
          send_time: send_time
            ? moment.unix(parseInt(send_time) / 1000).format("LLL")
            : "N/A",
          gps_time: gps_time
            ? moment.unix(parseInt(gps_time) / 1000).format("LLL")
            : "N/A",
          event_type:
            event_type_id === 2
              ? "GPS"
              : event_type_id === 9
              ? "Wifi"
              : event_type_id === 10
              ? "Cell"
              : "Other",
          accuracy: location_accuracy,
        };
      }
    );

    const csvData = new Blob([ArrayToCsv(cleanedArray)], {
      type: "application/vnd.ms-excel;charset=utf-8",
    });

    FileSaver.saveAs(
      csvData,
      selectedDevice.name + "-" + selectedDevice.imei + "-export.csv"
    );
  };

  useEffect(() => {
    //mapRef && mapRef.leafletElement && mapRef.leafletElement.invalidateSize();
  });

  /*
  useEffect(() => {
    // Set up auto refresh
    setInterval(() => {
      refetchReportData && refetchReportData();

      refetch &&
        refetch()
          .then((res) => (res.data && res.data.length > 0 ? res.data[0] : null))
          .then((device) => {
            if (device && device.last_report) {
              let selectedReport = JSON.parse(device.last_report);

              selectedReport.send_time = moment
                .utc(selectedReport.send_time)
                .valueOf();
              if (selectedReport.gps_time) {
                selectedReport.gps_time = moment
                  .utc(selectedReport.gps_time)
                  .valueOf();
              }
              //console.log({ selectedReport });
              setSelectedReport(selectedReport);
            }
          });
    }, 60000);
  }, []);
  */

  console.log({ selectedDevice });

  return (
    <Container
      style={{
        //paddingTop: isMobile ? 0 : "15px",
        textAlign: "left",
      }}
    >
      <Panel
        bordered
        shaded
        header={
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <Button
                  size="sm"
                  className="button-shadow"
                  appearance="primary"
                  onClick={() => setSelectedDevice(null)}
                >
                  <Icon icon="arrow-left" /> Devices
                </Button>
              </Col>
              <Col xs={12}>
                <Container style={{ width: "100%", alignItems: "flex-end" }}>
                  <ButtonGroup style={{ alignItems: "flex-end" }}>
                    {user &&
                      user.role_id &&
                      (user.role_id === 1 || user.role_id === 2) && (
                        <Button
                          appearance="subtle"
                          onClick={() => setShowLotPicker(true)}
                          color="green"
                        >
                          <span>
                            <Icon icon="circle-o" />
                            {!isMobile && " Lots"}
                          </span>
                        </Button>
                      )}
                    <Button
                      appearance="subtle"
                      onClick={() => exportCsv()}
                      color="blue"
                    >
                      <span>
                        <Icon icon="file-excel-o" />
                        {!isMobile && " Export"}
                      </span>
                    </Button>
                    <Button
                      appearance="subtle"
                      onClick={() => {
                        setShowRepoModal(true);
                      }}
                      color="red"
                    >
                      <span>
                        <Icon icon="car" /> Repo
                      </span>
                    </Button>
                    {/*
                    <Button
                      appearance="subtle"
                      onClick={() => setShowGeofencesModal(true)}
                      color="green"
                    >
                      <span>
                        <Icon icon="circle-o" />
                        {!isMobile && " Geofences"}
                      </span>
                    </Button>
                    */}
                    <Button
                      appearance="subtle"
                      onClick={() => setShowModeChangeModal(true)}
                      color="orange"
                      disabled={
                        !unsent_commands ||
                        (unsent_commands.push_commands &&
                          unsent_commands.push_commands.length > 0)
                      }
                    >
                      <span>
                        <Icon icon="file-text" />
                        {!isMobile && " Change Mode"}
                      </span>
                    </Button>
                  </ButtonGroup>
                </Container>
              </Col>
            </Row>
          </Grid>
        }
        style={{
          background: "rgba(255, 255, 255, 0.6)",
          backdropFilter: "blur(25px)",
        }}
      >
        {loading ? (
          <Loader content="Loading..." />
        ) : (
          <Grid fluid>
            <Row>
              <Col xs={24} md={12}>
                <Minimap
                  zoomControl={true}
                  dragging={true}
                  width="100%"
                  height={
                    isMobile ? "calc(50vh - 200px)" : "calc(100vh - 215px)"
                  }
                  coords={
                    selectedReport &&
                    selectedReport.location &&
                    selectedReport.location.latitude &&
                    selectedReport.location.longitude
                      ? {
                          lat: selectedReport.location.latitude,
                          lng: selectedReport.location.longitude,
                        }
                      : { lat: 51.505, lng: -0.09, zoom: 13 }
                  }
                  radius={
                    selectedReport && selectedReport.event_type_id
                      ? selectedReport.event_type_id === 10
                        ? parseFloat(selectedReport.location_accuracy)
                        : null
                      : selectedDevice.event_type_id === 10
                      ? parseFloat(selectedDevice.location_accuracy)
                      : null
                  }
                  showEventType
                  showGoogleLink
                  eventTypeId={
                    selectedReport && selectedReport.event_type_id
                      ? selectedReport.event_type_id
                      : selectedDevice.event_type_id
                  }
                  label={
                    selectedReport && selectedReport.gps_time
                      ? tools.getAgoString(selectedReport.gps_time)
                      : tools.getAgoString(selectedDevice.gps_time)
                  }
                />
              </Col>
              <Col xs={24} md={12}>
                <Grid fluid style={{ marginBottom: "10px", padding: 0 }}>
                  <Row>
                    <Col xs={12}>
                      <small style={{ color: "#aaaaaa" }}>DEVICE</small>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <small style={{ color: "#aaaaaa" }}>LAST REPORT</small>
                    </Col>
                  </Row>
                  <Row>
                    <Modal show={showEditDevice}>
                      <Modal.Header closeButton={false}>
                        <Modal.Title>Device</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <FormGroup>
                          <InputGroup style={{ width: "100%" }}>
                            <InputGroup.Addon>
                              <Icon icon="tag" />
                            </InputGroup.Addon>
                            <Input value={editName} onChange={setEditName} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup style={{ width: "100%" }}>
                            <InputGroup.Addon>
                              <Icon icon="car" />
                            </InputGroup.Addon>
                            <Input
                              value={editAttachedTo}
                              onChange={setEditAttachedTo}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Modal.Body>
                      <Modal.Footer>
                        <ButtonGroup>
                          <Button
                            color="green"
                            onClick={() => {
                              setShowEditDevice(false);
                              apolloClient
                                .mutate({
                                  mutation: GQL.Mutations.update_device,
                                  variables: {
                                    id: selectedDevice.id,
                                    name: editName,
                                    attached_to: editAttachedTo,
                                  },
                                })
                                .then((res) => refetch());
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            color="orange"
                            onClick={() => setShowEditDevice(false)}
                          >
                            Cancel
                          </Button>
                        </ButtonGroup>
                      </Modal.Footer>
                    </Modal>
                    <Col
                      xs={12}
                      onClick={() => setShowEditDevice(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <Whisper
                        speaker={
                          <Tooltip placement="right">
                            <Icon icon="pencil" /> Edit
                          </Tooltip>
                        }
                      >
                        <span style={{ fontSize: "32px" }}>
                          {selectedDevice.name}
                        </span>
                      </Whisper>
                      {/*
                      <EditableInput
                        defaultValue={selectedDevice.name}
                        size="xl"
                        fontSize="32px"
                      />
                      */}
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <span style={{ fontSize: "32px" }}>
                        {(() => {
                          if (
                            data &&
                            data.devices &&
                            data.devices[0] &&
                            data.devices[0].last_report &&
                            data.devices[0].last_report.length > 0
                          ) {
                            const last_report = JSON.parse(
                              data.devices[0].last_report
                            );
                            return tools.getEventType(
                              last_report.event_type_id
                            );
                          } else {
                            return "N/A";
                          }
                        })()}
                      </span>
                      {/*
                      <span style={{ fontSize: "32px" }}>
                        {unsent_commands &&
                        unsent_commands.push_commands &&
                        unsent_commands.push_commands.length > 0 ? (
                          <Whisper
                            speaker={<Tooltip>Pending mode change</Tooltip>}
                            placement="top"
                          >
                            <Icon
                              icon="info-circle"
                              size="2x"
                              style={{ marginRight: "5px" }}
                            />
                          </Whisper>
                        ) : (
                          ""
                        )}
                        {data &&
                        data.devices &&
                        data.devices.length > 0 &&
                        data.devices[0] &&
                        data.devices[0].device_mode &&
                        data.devices[0].device_mode.name
                          ? data.devices[0].device_mode.name
                          : "N/A"}
                      </span>
												*/}
                    </Col>
                  </Row>
                </Grid>
                <Container style={{ marginBottom: "5px" }}>
                  <small style={{ color: "#aaaaaa" }}>DETAILS</small>
                </Container>
                <Grid fluid>
                  <Row>
                    <Col xs={1} style={{ textAlign: "center" }}>
                      <Whisper
                        speaker={<Tooltip>Address</Tooltip>}
                        placement="top"
                      >
                        <Icon icon="home" style={{ color: "#2980B9" }} />
                      </Whisper>
                    </Col>
                    <Col xs={14} style={{ textAlign: "left" }}>
                      {selectedDevice.last_report &&
                      selectedDevice.last_report.address
                        ? selectedDevice.last_report.address
                        : "N/A"}
                    </Col>
                    <Col xs={8} style={{ textAlign: "right" }}>
                      {moment.utc(selectedDevice.enrolled_date).format("ll")}
                    </Col>
                    <Col xs={1} style={{ textAlign: "center" }}>
                      <Whisper
                        speaker={<Tooltip>Enrolled Date</Tooltip>}
                        placement="top"
                      >
                        <Icon icon="calendar" style={{ color: "#2980B9" }} />
                      </Whisper>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} style={{ textAlign: "center" }}>
                      <Whisper
                        speaker={<Tooltip>Battery Life</Tooltip>}
                        placement="top"
                      >
                        <Icon
                          icon={(() => {
                            if (
                              !selectedDevice.last_report ||
                              !selectedDevice.last_report.battery_level
                            ) {
                              return "ban";
                            }

                            const percent = !isNaN(
                              parseFloat(
                                selectedDevice.last_report.battery_level
                              )
                            )
                              ? parseFloat(
                                  selectedDevice.last_report.battery_level
                                )
                              : 0;

                            if (percent >= 80) {
                              return "battery";
                            } else if (percent >= 60) {
                              return "battery-3";
                            } else if (percent >= 40) {
                              return "battery-2";
                            } else if (percent >= 20) {
                              return "battery-1";
                            } else if (percent > 0) {
                              return "battery-0";
                            } else {
                              return "ban";
                            }
                          })()}
                          style={{
                            color: (() => {
                              if (
                                !selectedDevice.last_report ||
                                !selectedDevice.last_report.battery_level
                              ) {
                                return "red";
                              }

                              const percent = !isNaN(
                                selectedDevice.last_report.battery_level
                              )
                                ? selectedDevice.last_report.battery_level
                                : 0;

                              if (percent > 80) {
                                return "#27AE60";
                              } else if (percent > 60) {
                                return "#27AE60";
                              } else if (percent > 40) {
                                return "#F39C12";
                              } else if (percent > 20) {
                                return "#D35400";
                              } else if (percent > 0) {
                                return "#C0392B";
                              } else {
                                return "#C0392B";
                              }
                            })(),
                          }}
                        />
                      </Whisper>
                    </Col>
                    <Col xs={11} style={{ textAlign: "left" }}>
                      {isNaN(
                        parseFloat(
                          selectedDevice.last_report &&
                            selectedDevice.last_report.battery_level
                            ? selectedDevice.last_report.battery_level
                            : 0
                        )
                      )
                        ? "N/A"
                        : parseInt(
                            parseFloat(
                              selectedDevice.last_report &&
                                selectedDevice.last_report.battery_level
                                ? selectedDevice.last_report.battery_level
                                : 0
                            )
                          ) + " %"}
                    </Col>
                    <Col xs={11} style={{ textAlign: "right" }}>
                      {parseInt(data.devices[0].report_count).toLocaleString()}
                    </Col>
                    <Col xs={1} style={{ textAlign: "center" }}>
                      <Whisper
                        speaker={<Tooltip>Report Count</Tooltip>}
                        placement="top"
                      >
                        <Icon icon="file-text" style={{ color: "#2980B9" }} />
                      </Whisper>
                    </Col>
                  </Row>
                </Grid>
                <Container
                  style={{
                    padding: "8px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                  }}
                >
                  <RangeSlider
                    //disabled={rangeDisabled}
                    step={15}
                    min={0}
                    max={maxReportDays + 1}
                    graduated
                    tooltip={false}
                    defaultValue={[start, end]}
                    value={[start, end]}
                    onChange={(value) => {
                      setStart(value[0]);
                      setEnd(value[1]);
                      refetchReportData();
                    }}
                    renderMark={(value) => {
                      if ([0, 30, 60, 90, 120, 150, 180].includes(value)) {
                        if (value === maxReportDays + 1) {
                          return "Now";
                        } else if (value === maxReportDays) {
                          return "Today";
                        } else if (value === maxReportDays - 1) {
                          return "Yesterday";
                        } else {
                          return moment()
                            .subtract(maxReportDays - value, "days")
                            .format("MMM Do");
                        }
                      }
                    }}
                  />
                </Container>
                <Container>
                  {loadingReportData ? (
                    <Loader content="Loading..." />
                  ) : (
                    <Table
                      virtualized
                      fluid
                      flex
                      data={reportData.device_reports}
                      //rowHeight={75}
                      //autoHeight
                      height={
                        isMobile
                          ? parseInt(window.innerHeight / 2) - 200
                          : window.innerHeight - 404
                      }
                      onRowClick={(report) => {
                        //console.log({ report });
                        if (
                          report.location &&
                          report.location.latitude &&
                          report.location.longitude
                        ) {
                          setSelectedReport(report);
                        }
                      }}
                      style={{
                        borderRadius: "5px",
                        //border: "1px #eaeaea solid",
                      }}
                      className="button-shadow"
                    >
                      <Table.Column>
                        <Table.HeaderCell>Event</Table.HeaderCell>
                        <Table.Cell style={{ textAlign: "left" }}>
                          {(report) => {
                            return (
                              <>
                                <EventTypeIcon
                                  icon="map-marker"
                                  label="GPS Location"
                                  active={report.event_type_id === 2}
                                />{" "}
                                <EventTypeIcon
                                  icon="wifi"
                                  label="Wifi Location"
                                  active={report.event_type_id === 9}
                                />{" "}
                                <EventTypeIcon
                                  icon="signal"
                                  label="Cell Location"
                                  active={report.event_type_id === 10}
                                />
                              </>
                            );
                          }}
                        </Table.Cell>
                      </Table.Column>
                      <Table.Column>
                        <Table.HeaderCell>Signal</Table.HeaderCell>
                        <Table.Cell>
                          {(report) => {
                            if (report.event_type_id === 2) {
                              // GPS
                              const label =
                                parseInt(report.location_accuracy) +
                                " satellites";
                              const percent = Math.min(
                                parseInt(report.location_accuracy) * 9,
                                100
                              );
                              let color = "#C0392B";
                              if (percent > 75) color = "#27AE60";
                              else if (percent > 50) color = "#F39C12";
                              else if (percent > 25) color = "#D35400";

                              return (
                                <Whisper speaker={<Tooltip>{label}</Tooltip>}>
                                  <Progress.Line
                                    percent={percent}
                                    strokeColor={color}
                                    showInfo={false}
                                  />
                                </Whisper>
                              );
                            } else if (report.event_type_id === 9) {
                              // WIFI
                              const label =
                                parseInt(report.location_accuracy) +
                                " WiFi Points";
                              const percent = Math.min(
                                parseInt(report.location_accuracy) * 17,
                                100
                              );
                              let color = "#C0392B";
                              if (percent > 75) color = "#27AE60";
                              else if (percent > 50) color = "#F39C12";
                              else if (percent > 25) color = "#D35400";

                              return (
                                <Whisper speaker={<Tooltip>{label}</Tooltip>}>
                                  <Progress.Line
                                    percent={percent}
                                    strokeColor={color}
                                    showInfo={false}
                                  />
                                </Whisper>
                              );
                            } else if (report.event_type_id === 10) {
                              // LTE
                              const label =
                                parseInt(report.location_accuracy) + " meters";
                              const percent = Math.max(
                                0,
                                100 - parseInt(report.location_accuracy) / 5
                              );
                              let color = "#C0392B";
                              if (percent > 90) color = "#27AE60";
                              else if (percent > 70) color = "#F39C12";
                              else if (percent > 50) color = "#D35400";

                              return (
                                <Whisper speaker={<Tooltip>{label}</Tooltip>}>
                                  <Progress.Line
                                    percent={percent}
                                    strokeColor={color}
                                    showInfo={false}
                                  />
                                </Whisper>
                              );
                            }
                          }}
                        </Table.Cell>
                      </Table.Column>
                      <Table.Column flexGrow={2}>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.Cell dataKey="address" />
                      </Table.Column>
                      <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Time</Table.HeaderCell>
                        <Table.Cell>
                          {(report) =>
                            report.gps_time
                              ? moment
                                  .unix(
                                    parseInt(report.gps_time) / 1000,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                  .local()
                                  .format("llll")
                              : moment
                                  .unix(
                                    parseInt(report.send_time) / 1000,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                  .local()
                                  .format("llll")
                          }
                        </Table.Cell>
                      </Table.Column>
                    </Table>
                  )}
                </Container>
              </Col>
            </Row>
          </Grid>
        )}
      </Panel>
      <Modal show={showLotPicker} size={isMobile ? "xs" : "lg"}>
        <Modal.Header onHide={() => setShowLotPicker(false)}>
          <span>
            <Icon icon="circle-o" /> Select Lots (5 Max)
          </span>
        </Modal.Header>
        <Modal.Body>
          <LotPicker
            selectedDevice={selectedDevice}
            onClose={() => setShowLotPicker(false)}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showRepoModal} size={isMobile ? "xs" : "lg"}>
        <Modal.Header onHide={() => setShowRepoModal(false)}>
          <span>
            <Icon icon="car" /> Repo
          </span>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <InputGroup>
              <InputGroup.Addon>
                <Icon icon="at" />
              </InputGroup.Addon>
              <Input value={email} placeholder="Email" onChange={setEmail} />
              <InputGroup.Addon>
                <Button
                  disabled={email.trim().length == 0}
                  onClick={() => {
                    const expires_at = moment
                      .utc()
                      .add(7, "days")
                      .format("YYYY-MM-DD HH:mm:SS");

                    create_repo_token({
                      variables: {
                        device_id: selectedDevice.id,
                        email,
                        expires_at,
                      },
                    }).then((res) => {
                      setEmail("");
                      refetch_repo_tokens();
                    });
                  }}
                >
                  <Icon icon="send" /> Create
                </Button>
              </InputGroup.Addon>
            </InputGroup>
            <Table
              loading={loading_repo_tokens}
              data={
                repo_tokens && repo_tokens.repo_tokens
                  ? repo_tokens.repo_tokens
                  : []
              }
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.Cell dataKey="email" />
              </Table.Column>
              <Table.Column flexGrow={2}>
                <Table.HeaderCell>Link</Table.HeaderCell>
                <Table.Cell>
                  {(repo_token) => {
                    if (repo_token.active === false) {
                      return <span>DISABLED</span>;
                    } else if (
                      parseInt(repo_token.expires_at) < moment().valueOf()
                    ) {
                      return <span>EXPIRED</span>;
                    } else {
                      return (
                        <span>
                          <a
                            href={
                              "https://track.pulsewrx.com/repo/" +
                              repo_token.token
                            }
                            target="_blank"
                          >
                            {"https://track.pulsewrx.com/repo/" +
                              repo_token.token}
                          </a>
                        </span>
                      );
                    }
                  }}
                </Table.Cell>
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Expires</Table.HeaderCell>
                <Table.Cell>
                  {(repo_token) =>
                    moment
                      .unix(parseInt(repo_token.expires_at / 1000))
                      .format("MM/DD/YYYY")
                  }
                </Table.Cell>
              </Table.Column>
              <Table.Column width={100}>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.Cell>
                  {(repo_token) => {
                    if (repo_token.active === false) {
                      // Disabled tokens
                      return (
                        <ButtonGroup>
                          <Whisper
                            speaker={
                              <Tooltip placement="left">Enable Token</Tooltip>
                            }
                          >
                            <Button
                              color="green"
                              size="xs"
                              onClick={() => {
                                enable_repo_token({
                                  variables: {
                                    id: repo_token.id,
                                  },
                                }).then((res) => {
                                  if (
                                    res &&
                                    res.data &&
                                    res.data.enable_repo_token
                                  ) {
                                    refetch_repo_tokens();
                                  } else {
                                    Alert.error("Error enabling repo token");
                                  }
                                });
                              }}
                            >
                              <Icon icon="refresh" />
                            </Button>
                          </Whisper>
                          <Whisper speaker={<Tooltip>Delete Token</Tooltip>}>
                            <Button
                              color="red"
                              size="xs"
                              onClick={() => {
                                delete_repo_token({
                                  variables: { id: repo_token.id },
                                }).then((res) => {
                                  if (
                                    res &&
                                    res.data &&
                                    res.data.delete_repo_token
                                  ) {
                                    refetch_repo_tokens();
                                  } else {
                                    Alert.error("Error deleting repo token");
                                  }
                                });
                              }}
                            >
                              <Icon icon="trash" />
                            </Button>
                          </Whisper>
                        </ButtonGroup>
                      );
                    } else if (
                      parseInt(repo_token.expires_at) < moment().valueOf()
                    ) {
                      return (
                        <Whisper speaker={<Tooltip>Delete Token</Tooltip>}>
                          <Button
                            color="red"
                            size="xs"
                            onClick={() => {
                              delete_repo_token({
                                variables: { id: repo_token.id },
                              }).then((res) => {
                                if (
                                  res &&
                                  res.data &&
                                  res.data.delete_repo_token
                                ) {
                                  refetch_repo_tokens();
                                } else {
                                  Alert.error("Error deleting repo token");
                                }
                              });
                            }}
                          >
                            <Icon icon="trash" />
                          </Button>
                        </Whisper>
                      );
                    } else {
                      // Active tokens
                      return (
                        <Whisper speaker={<Tooltip>Disable Token</Tooltip>}>
                          <Button
                            color="orange"
                            size="xs"
                            onClick={() => {
                              disable_repo_token({
                                variables: {
                                  id: repo_token.id,
                                },
                              }).then((res) => {
                                if (
                                  res &&
                                  res.data &&
                                  res.data.disable_repo_token
                                ) {
                                  refetch_repo_tokens();
                                } else {
                                  Alert.error("Error disabling repo token");
                                }
                              });
                            }}
                          >
                            <Icon icon="ban" />
                          </Button>
                        </Whisper>
                      );
                    }
                  }}
                </Table.Cell>
              </Table.Column>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="orange"
            onClick={() => {
              setShowRepoModal(false);
              setEmail("");
            }}
            appearance="subtle"
          >
            <Icon icon="check" /> Done
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showGeofencesModal} size={isMobile ? "xs" : "lg"}>
        <Modal.Header onHide={() => setShowGeofencesModal(false)}>
          <span>
            <Icon icon="circle-o" /> Geofences
          </span>
        </Modal.Header>
        <Modal.Body>
          <Geofences
            selectedDevice={selectedDevice}
            onClose={() => setShowGeofencesModal(false)}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showModeChangeModal}
        full={isMobile ? true : false}
        size="lg"
        className="warning-modal"
      >
        <ModeSelect
          selectedDevice={
            data && data.devices && data.devices.length > 0 && data.devices[0]
              ? data.devices[0]
              : null
          }
          onHide={() => setShowModeChangeModal(false)}
          refetch={refetch}
          refetch_unsent_commands={refetch_unsent_commands}
        />
      </Modal>
    </Container>
  );
};
