import React, { useCallback, useState, useEffect, useRef } from "react";
import _, { last } from "lodash";
import {
  Container,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Grid,
  Row,
  Col,
} from "rsuite";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

import Details from "./details";
import Map from "./map";

// GQL
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import GQL from "./graphql";

const isMobile = window.innerWidth < 992;
//const isBigScreen = window.innerWidth > 1920;

const LastReportCell = ({ device: incoming_device, type }) => {
  const [device, setDevice] = useState(null);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (incoming_device.last_report) {
      setDevice(incoming_device);
    } else {
      apolloClient
        .query({
          query: GQL.Queries.device_lastreport,
          variables: {
            id: incoming_device.id,
          },
        })
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.devices &&
            res.data.devices.length > 0
          ) {
            setDevice({
              ...incoming_device,
              last_report: res.data.devices[0].last_report,
            });
          }
        });
    }
  }, []);

  if (device == null) {
    return <span>Loading...</span>;
  }

  if (type == "device") {
    const battery_level =
      device.last_report && device.last_report.battery_level
        ? parseFloat(device.last_report.battery_level)
        : 0;
    const percent = !isNaN(battery_level) ? parseInt(battery_level) : 0;
    let color = "#C0392B";
    let icon = "ban";

    if (percent >= 80) {
      color = "#27AE60";
      icon = "battery";
    } else if (percent >= 60) {
      color = "#27AE60";
      icon = "battery-3";
    } else if (percent >= 40) {
      color = "#F39C12";
      icon = "battery-2";
    } else if (percent >= 20) {
      color = "#D35400";
      icon = "battery-1";
    } else if (percent > 0) {
      color = "#C0392B";
      icon = "battery-0";
    }

    return (
      <Whisper speaker={<Tooltip>{percent} %</Tooltip>}>
        <Icon icon={icon} style={{ color }} />
      </Whisper>
    );
  } else if (type == "address") {
    return device.last_report && device.last_report.address ? (
      <span>{device.last_report.address}</span>
    ) : (
      <span>N/A</span>
    );
  } else if (type == "send_time") {
    return device.last_report && device.last_report.send_time ? (
      <span>{tools.getAgoString(device.last_report.send_time)}</span>
    ) : (
      <span>N/A</span>
    );
  } else {
    return <span>N/A</span>;
  }
};

export default (props) => {
  const [{ globalFilters }] = useApp();
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState("");
  const [useFilter, setUseFilter] = useState("");
  const [filteredDevices, setFilteredDevices] = useState([]);

  const {
    data: devices,
    loading: loadingDevices,
    //refetch: refetchDevices,
  } = useQuery(GQL.Queries.devices, {
    onCompleted: (data) => {
      setFilteredDevices(data.devices);
    },
  });

  const {
    data: devices_with_last_report,
    loading: loadingDevicesWithLastReport,
    refetch: refetchDevicesWithLastReport,
  } = useQuery(GQL.Queries.devices_with_last_report, {
    onCompleted: (data) => {
      setFilteredDevices(data.devices);
    },
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });

  const doFilter = useCallback(
    _.debounce((q) => setUseFilter(q), 500),
    []
  );
  const onFilterChange = (value) => {
    setDeviceFilter(value);
    doFilter(value);
  };

  const exportCsv = () => {
    //console.log(reportData.device_reports);

    const cleanedArray = filteredDevices.map(
      ({ imei, name, last_report, device_mode }) => ({
        imei,
        name,
        address: last_report.address,
        device_mode: device_mode.name,
        last_report: moment
          .unix(parseInt(last_report.send_time / 1000))
          .format("MM/DD/YYYY h:mm:ss a"),
      })
    );

    const csvData = new Blob([ArrayToCsv(cleanedArray)], {
      type: "application/vnd.ms-excel;charset=utf-8",
    });

    FileSaver.saveAs(csvData, "devices-export.csv");
  };

  useEffect(() => {
    if (devices_with_last_report && devices_with_last_report.devices) {
      if (globalFilters.length > 0) {
        setFilteredDevices(
          devices_with_last_report.devices.filter((device) => {
            //console.log({ device });
            if (device && device.last_report && device.last_report) {
              if (
                device.last_report.battery_level &&
                parseFloat(device.last_report.battery_level) < 20 &&
                globalFilters.includes("lowbattery")
              ) {
                return true;
              }
              if (
                device.device_mode_id &&
                [1, 26].includes(device.device_mode_id) &&
                globalFilters.includes("recovery")
              ) {
                return true;
              }
            }
            return false;
          })
        );
      } else if (useFilter.length > 0) {
        setFilteredDevices(
          devices_with_last_report.devices.filter(
            (device) =>
              device.name &&
              useFilter &&
              device.name.toLowerCase().includes(useFilter.toLowerCase())
          )
        );
      } else {
        setFilteredDevices(devices_with_last_report.devices);
      }
    }
  }, [useFilter, devices_with_last_report, globalFilters]);

  /*
  useEffect(() => {
    // Set up auto refresh
    setInterval(
      () => refetchDevicesWithLastReport && refetchDevicesWithLastReport(),
      60000
    );
  }, []);
  */

  return selectedDevice ? (
    <Details
      setSelectedDevice={setSelectedDevice}
      selectedDevice={selectedDevice}
    />
  ) : (
    <Container
      style={{
        //paddingTop: isMobile ? 0 : "15px",
        textAlign: "left",
      }}
    >
      <Panel
        bordered
        shaded
        header={
          <InputGroup>
            <Input
              placeholder="filter"
              value={deviceFilter}
              onChange={onFilterChange}
            />
            <InputGroup.Button
              appearance="subtle"
              onClick={() => exportCsv()}
              color="blue"
            >
              <span>
                <Icon icon="file-excel-o" />
                {!isMobile && " Export"}
              </span>
            </InputGroup.Button>
          </InputGroup>
        }
        style={{
          background: "rgba(255, 255, 255, 0.6)",
          backdropFilter: "blur(25px)",
        }}
      >
        {loadingDevices ? (
          <Loader content="Loading..." vertical />
        ) : (
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <Container>
                  <Container style={{ textAlign: "left" }}>
                    <small>
                      <Icon icon="filter" />{" "}
                      {filteredDevices ? filteredDevices.length : "?"} /{" "}
                      {devices.devices.length} devices
                    </small>
                  </Container>
                  <Table
                    data={filteredDevices}
                    //rowHeight={100}
                    //autoHeight
                    //height={sidePanelHeight}
                    height={window.innerHeight - 200}
                    className="button-shadow"
                    style={{ borderRadius: "7px" }}
                    onRowClick={(device) => {
                      setSelectedDevice(device);
                    }}
                    virtualized
                  >
                    <Table.Column>
                      <Table.HeaderCell>Device</Table.HeaderCell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {(device) => {
                          const last_report = JSON.parse(device.last_report);
                          const battery_level =
                            last_report && last_report.battery_level
                              ? parseFloat(last_report.battery_level)
                              : 0;
                          const percent = !isNaN(battery_level)
                            ? parseInt(battery_level)
                            : 0;
                          let color = "#C0392B";
                          let icon = "ban";

                          if (percent >= 80) {
                            color = "#27AE60";
                            icon = "battery";
                          } else if (percent >= 60) {
                            color = "#27AE60";
                            icon = "battery-3";
                          } else if (percent >= 40) {
                            color = "#F39C12";
                            icon = "battery-2";
                          } else if (percent >= 20) {
                            color = "#D35400";
                            icon = "battery-1";
                          } else if (percent > 0) {
                            color = "#C0392B";
                            icon = "battery-0";
                          }

                          return (
                            <Whisper speaker={<Tooltip>{percent} %</Tooltip>}>
                              <Icon icon={icon} style={{ color }} />
                            </Whisper>
                          );
                        }}
                      </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={isMobile ? 1 : 2}>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.Cell>
                        {(device) => (
                          <span>
                            {device
                              ? device.name && device.name.length > 0
                                ? device.name
                                : device.imei
                              : "N/A"}
                          </span>
                        )}
                      </Table.Cell>
                    </Table.Column>
                    {!isMobile ? (
                      <Table.Column flexGrow={2}>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.Cell>
                          {
                            (device) => {
                              if (device.last_report != null) {
                                const last_report = JSON.parse(
                                  device.last_report
                                );
                                //console.log({ last_report });
                                return last_report.address
                                  ? last_report.address
                                  : "N/A";
                              } else {
                                return "N/A";
                              }
                            }
                            //device => <LastReportCell device={device} type="address" />
                            /*(device) =>
                      device.last_report && device.last_report.address
                        ? device.last_report.address
                        : "N/A"
                    */
                          }
                        </Table.Cell>
                      </Table.Column>
                    ) : null}
                    <Table.Column flexGrow={1}>
                      <Table.HeaderCell>Latest Report</Table.HeaderCell>
                      <Table.Cell>
                        {
                          (device) =>
                            device.last_report_date
                              ? tools.getAgoString(device.last_report_date)
                              : "N/A"
                          //device => <LastReportCell device={device} type="send_time" />
                          /*(device) =>
                    device.last_report && device.last_report.send_time
                      ? tools.getAgoString(device.last_report.send_time)
                      : "N/A"
                  */
                        }
                      </Table.Cell>
                    </Table.Column>
                    {!isMobile ? (
                      <Table.Column
                        minWidth={250}
                        flexGrow={1}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Table.HeaderCell>Last Event</Table.HeaderCell>
                        <Table.Cell>
                          {(device) => {
                            if (device.last_report) {
                              const last_report = JSON.parse(
                                device.last_report
                              );
                              if (last_report && last_report.event_type_id) {
                                return tools.getEventType(
                                  last_report.event_type_id
                                );
                              }
                            } else {
                              return "N/A";
                            }
                          }}
                        </Table.Cell>
                      </Table.Column>
                    ) : null}
                  </Table>
                </Container>
              </Col>
              <Col xs={12}>
                <Map
                  zoomControl={true}
                  dragging={true}
                  width="100%"
                  height={
                    isMobile ? "calc(50vh - 200px)" : "calc(100vh - 185px)"
                  }
                  selectedDevice={selectedDevice}
                  coords={filteredDevices
                    .filter((d) => {
                      const last_report = JSON.parse(d.last_report);
                      return (
                        last_report &&
                        last_report.location &&
                        last_report.location.latitude &&
                        last_report.location.longitude &&
                        last_report.location.latitude >= -90 &&
                        last_report.location.latitude <= 90 &&
                        last_report.location.longitude >= -180 &&
                        last_report.location.longitude <= 180
                      );
                    })
                    .map((d) => {
                      //console.log({ d });
                      const last_report = JSON.parse(d.last_report) || {};
                      console.log(last_report.location);
                      return {
                        lat: last_report.location.latitude,
                        lng: last_report.location.longitude,
                      };
                    })}
                  // { lat: 51.505, lng: -0.09, zoom: 13 }}
                  //radius={null}
                  //label={"TEST"}
                />
              </Col>
            </Row>
          </Grid>
        )}
      </Panel>
    </Container>
  );
};
